import { clsx } from 'clsx';
import { useContext, useState } from 'react';
import { YandexContext } from '../yandex-context';
import { useNavigate } from 'react-router-dom';
import styles from './instant-purchase-button.module.css'
import { ModalInstantPurchase } from '../modal-instant-purchase';

export function InstantPurchaseButton({product, onClick}) {
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();

    function instantPurchase() {
        // onClick(product);
        console.log(product);
        navigate(`/checkout?instantPurchaseSku=${product.sku}`);
    }

    function instantPurchaseHandler() {
        const loggedIn = !!localStorage.getItem('cart_id');

        if (!loggedIn) {
            setShowModal(true);
        } else {
            instantPurchase()
        }
    }

    return(
        <>
            {showModal && <ModalInstantPurchase show={showModal} setShowModal={setShowModal} product={product} onConfirm={instantPurchase}/>}
            <button className={clsx("button_main_color-rounded", styles.button)} onClick={instantPurchaseHandler}>
                Быстрая покупка
            </button>
        </>
    )
}
