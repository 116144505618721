const Filters = [
    {
        databaseName: 'Доступность на складе',
        frontendAlias: 'Доступность на складе',
        values: [],
        alwaysOpenDropdown: true,
    },
    {
        databaseName: 'Бренд',
        frontendAlias: 'Производитель',
        values: [],
    },
    {
        databaseName: 'Модель',
        frontendAlias: 'Модель',
        values: [],
    },
    {
        databaseName: 'Вес',
        frontendAlias: 'Вес',
        values: [],
    },
    {
        databaseName: 'Процессор',
        frontendAlias: 'Процессор',
        values: [],
    },
    {
        databaseName: 'Серия',
        frontendAlias: 'Серия процессора',
        values: [],
    },
    {
        databaseName: 'Индекс',
        frontendAlias: 'Индекс процессора',
        values: [],
    },
    {
        databaseName: 'Количество ядер',
        frontendAlias: 'Количество ядер процессора',
        values: [],
    },
    {
        databaseName: 'Общий объем накопителей',
        frontendAlias: 'Общий объем накопителей',
        values: [],
    },
    {
        databaseName: 'Оперативная память',
        frontendAlias: 'ОЗУ (ГБ)',
        values: [],
    },
    {
        databaseName: 'Частота оперативной памяти',
        frontendAlias: 'Частота оперативной памяти',
        values: [],
    },
    {
        databaseName: 'Набор микросхем (chipset)',
        frontendAlias: 'Набор микросхем видеокарты',
        values: [],
    },
    {
        databaseName: 'Объем видеопамяти',
        frontendAlias: 'Объем видеопамяти',
        values: [],
    },
    {
        databaseName: 'Мощность (TGP)',
        frontendAlias: 'Мощность (TGP) видеокарты',
        values: [],
    },
    {
        databaseName: 'LTE',
        frontendAlias: 'LTE',
        values: [],
    },
    {
        databaseName: 'Экран',
        frontendAlias: 'Размер экрана',
        values: [],
    },
    {
        databaseName: 'Разрешение экрана',
        frontendAlias: 'Разрешение экрана',
        values: [],
    },
    {
        databaseName: 'Яркость экрана',
        frontendAlias: 'Яркость экрана',
        values: [],
    },
    {
        databaseName: 'Цветовой охват',
        frontendAlias: 'Цветовой охват',
        values: [],
    },
    {
        databaseName: 'Частота обновления экрана',
        frontendAlias: 'Частота обновления экрана',
        values: [],
    },
    {
        databaseName: 'Глянцевый',
        frontendAlias: 'Глянцевый',
        values: [],
        alwaysOpenDropdown: true,
    },
    {
        databaseName: 'Матовый',
        frontendAlias: 'Матовый',
        values: [],
        alwaysOpenDropdown: true,
    },
    {
        databaseName: 'Сенсорный',
        frontendAlias: 'Сенсорный экран',
        values: [],
    },
    {
        databaseName: 'Тип матрицы',
        frontendAlias: 'Тип матрицы',
        values: [],
    },
    {
        databaseName: 'Материал корпуса',
        frontendAlias: 'Материал корпуса',
        values: [],
    },
    {
        databaseName: 'Емкость батареи',
        frontendAlias: 'Емкость батареи (КВ*Ч)',
        values: [],
    },
    {
        databaseName: 'Время работы от батареи',
        frontendAlias: 'Максимальное время работы от батареи',
        values: [],
    },
    {
        databaseName: 'Операционная система',
        frontendAlias: 'Операционная система',
        values: [],
    },
    {
        databaseName: 'Сканер отпечатка',
        frontendAlias: 'Сканер отпечатка',
        values: [],
        alwaysOpenDropdown: true,
    },
    {
        databaseName: 'Сканер лица',
        frontendAlias: 'Сканер лица',
        values: [],
        alwaysOpenDropdown: true,
    },
    {
        databaseName: 'Габариты (ШхГхТ)',
        frontendAlias: 'Габариты',
        values: [],
    },
    {
        databaseName: 'Цвет',
        frontendAlias: 'Цвет',
        values: [],
    },
];

export default Filters;
