import { FormSelect } from 'react-bootstrap';

const SortBy = ({onSortChange, parameters}) => {
    return (
        <div
            className="sort-by"
            style={{
                paddingBottom: '10px',
                borderBottom: '1px solid rgba(227, 227, 227, 1)',
            }}>
            <h4 className="widget-title">Сортировать по</h4>
            <FormSelect aria-label="Default select example" value={parameters.orderBy} onChange={onSortChange}>
                {/*<option value={''}>Исходная сортировка</option>*/}
                <option value={['salePrice', 'ASC']}>Цена: по возрастанию</option>
                <option value={['salePrice', 'DESC']}>Цена: по убыванию</option>
                <option value={['name', 'ASC']}>Название: по алфавиту</option>
                <option value={['name', 'DESC']}>Название: в обратном порядке</option>
                <option value={['createdAt', 'ASC']}>По дате: сначала старые</option>
                <option value={['createdAt', 'DESC']}>По дате: сначала новые</option>
            </FormSelect>
        </div>
    );
}

export default SortBy;
