import { Table } from 'react-bootstrap';
import styles from './product-details.module.css'

const ProductDetails = ({ details }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '15px',
                borderColor: 'rgba(227, 227, 227, 1)',
                borderTopWidth: '1px',
                borderTopStyle: 'solid',
                fontFamily: 'Helvetica',
            }}>
            <h5>Детали</h5>
            <Table className={styles.table}>
                <tbody>
                    {!!details && details.map((element) => (
                        <tr>
                            <td style={{ textAlign: 'left' }}>
                                <strong>{element.name}</strong>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                {element.value}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ProductDetails;
