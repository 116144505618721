import '../../styles/header.css';

import Navbar from '../navbar/navbar';
import HeaderSocial from '../header-social/headerSocial';
import HeaderSearch from '../header-search';
import { useMediaQuery } from '../../helpers/hooks/useMediaQuery';
import { MobileHeader } from '../../mobile-components/mobile-header';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const matches = useMediaQuery('(min-width: 1000px)');
    const navigate = useNavigate()

    useEffect(() => {
        if(!localStorage.getItem('cart_id') && matches) {
            window.YaAuthSuggest.init(
                {
                    client_id: 'cd3c2fc7e0db4356a56de4ec36bcb60a',
                    response_type: 'token',
                    redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/yandex-oauth`,
                },
                `${process.env.REACT_APP_PUBLIC_URL}`,
                {
                    view: 'button',
                    parentId: 'yandexContainer',
                    buttonSize: 'm',
                    buttonView: 'main',
                    buttonTheme: 'light',
                    buttonBorderRadius: '0',
                    buttonIcon: 'ya',
                },
            )
                .then(({ handler }) => handler())
                .then((data) => {
                    localStorage.setItem('cart_id', data.access_token);
                    navigate(0);

                })
                .catch((error) => console.log('Обработка ошибки', error));
        }
    }, [matches])

    return (
        <div className="header">
            {matches ? (
                <>
                    <HeaderSocial />
                    <HeaderSearch />
                    <Navbar />
                </>
            ) : (
                <MobileHeader />
            )}
        </div>
    );
};

export default Header;
