const handleCartClick = (product, cart, setCart, quantity) => {
    const index = cart.indexOf(cart.find((item) => item.id === product.id));
    //Метод indexOf возвращает -1 если не находит элемент
    if (index === -1) {
        console.log(product);
        // Добавляем продукт в конец массива
        const newCart = [...cart, {...product, quantity: quantity ? quantity : 1}];
        // Обновляем состояние
        setCart(newCart);
        // Обновляем localStorage
        localStorage.setItem("cart", JSON.stringify(newCart));
        window.ym(87126302,'reachGoal','cart.add_to_cart');
        window.dispatchEvent(new Event('storage'));
    }
    else {
        console.log('else');
        //Создаем новый массив без этого элемента
        const newCart = [...cart.slice(0, index), ...cart.slice(index + 1, cart.length)]
        // Обновляем состояние
        setCart(newCart);
        // Обновляем localStorage
        localStorage.setItem("cart", JSON.stringify(newCart));
        window.dispatchEvent(new Event('storage'));
    }
}

export default handleCartClick;
