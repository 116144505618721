//Отзывы
import PageTitle from '../../components/page-title/pageTitle';
import styles from './reviews.module.css';
import { clsx } from 'clsx';

const Reviews = () => {
    window.ym(87126302,'reachGoal','reviews');

    return (
        <div className="reviewsWrapper">
            <PageTitle title="Отзывы" bottomText="Главная / Отзывы" />
            <div
                className={clsx('static-page_text', styles.reviews)}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100% !important',
                }}>
                Вы можете ознакомиться с отзывами наших покупателей по ссылкам
                ниже:
                <ul>
                    <li className="static-page_text-list-item">
                        <a
                            className={clsx('link', styles.link)}
                            href="https://yandex.ru/maps/org/ultra_nout/169046270181/reviews/?ll=37.592833%2C55.794143&z=10"
                            target="_blank"
                            rel="noreferrer">
                            На Яндекс Картах
                        </a>
                    </li>
                    <li className="static-page_text-list-item">
                        <a
                            className={clsx('link', styles.link)}
                            href="https://market.yandex.ru/business--ultra-nout/13529706/reviews"
                            target="_blank"
                            rel="noreferrer">
                            На Яндекс Маркете
                        </a>
                    </li>
                </ul>
                Для улучшения нашего сервиса нам очень важна обратная связь,
                наша репутация – это гарантия для будущих покупателей, поэтому
                мы всегда рады самым разным отзывам!
                <div
                    style={{
                        paddingTop: '10px',
                        borderColor: 'rgba(227, 227, 227, 1)',
                        borderWidth: '1px 0 0 0',
                        borderStyle: 'solid',
                    }}>
                    <div>
                        <p>
                            <cite className="article_decoration_first article_decoration_last">
                                Задайте нам вопрос:{' '}
                            </cite>
                        </p>
                        <p>
                            <cite className="article_decoration_first article_decoration_last">
                                +7 (499) 110-77-67;{' '}
                            </cite>
                        </p>
                        <p>
                            <cite className="article_decoration_first article_decoration_last">
                                ultranout@gmail.com
                            </cite>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reviews;
